import { RoleBasedComponent } from "@/utils/RoleBasedComponent";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type PropsNavigation = {
  tabs: Tab[];
};

const Navigation: React.FC<PropsNavigation> = ({ tabs }) => {
  const [currentRoute, setCurrentRoute] = useState("");
  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);
  const navigate = useNavigate();

  return (
    <div className="flex  pt-8 flex-col gap-2">
      {tabs.map((tab) => (
        <RoleBasedComponent allowedRoles={tab.allowedUsers}>
          <div
            onClick={() => navigate(tab.route)}
            className={` flex gap-5 p-8 items-center text-lg text-white cursor-pointer  transition duration-250 ease-in-out ${
              currentRoute === tab.route || currentRoute === "/"
                ? "font-medium text-textDark bg-muted bg-gradient-to-r from-[#677EEA] to-primary"
                : "text-[#848382]"
            }`}
          >
            {/* create the icon */}
            <span className="flex justify-start items-start">
              {tab.icon && <tab.icon />}
            </span>
            <span className="flex justify-start items-start">{tab.title}</span>
          </div>
        </RoleBasedComponent>
      ))}
    </div>
  );
};

export default Navigation;
