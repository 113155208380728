import Container from "../../components/Container";
import { getUser } from "@/utils/auth.util";
import ConsulationCard from "./ConsulationCard";
import Header from "../../components/Header";
import { useState } from "react";
import useGetConsultant from "../../hooks/useGetConsultant";
import useInterviewStats from "../../hooks/useInterviewStats";

type Props = {};

const Consultations = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState("");

  const { id } = getUser();
  const { loading, name, Interview } = useGetConsultant(id);

  const { totalConsulted, totalScheduled, totalRescheduled, totalCanceled } =
    useInterviewStats(Interview);
  return (
    <Container>
      <Header
        isSearchable
        placeholder="Consultations"
        setSearchTerm={setSearchTerm}
      />
      <div className="flex flex-col gap-8 p-12">
        <div className="flex gap-8 w-full">
          <div className="w-1/3 flex flex-col bg-white p-8 rounded-[5px] shadow-md">
            <h1 className="text-[20px] font-semibold">Scheduled Meetings</h1>
            <p className="text-[40px] font-semibold mt-8">{totalScheduled}</p>
            <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
              This is the number of students you consulted
            </p>
          </div>
          <div className="w-1/3 flex flex-col bg-white p-8 rounded-[5px] shadow-md">
            <h1 className="text-[20px] font-semibold">Rescheduled Meetings</h1>
            <p className="text-[40px] font-semibold mt-8">{totalRescheduled}</p>
            <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
              This is the number of students waiting consultation
            </p>
          </div>
          <div className="w-1/3 flex flex-col bg-white p-8 rounded-[5px] shadow-md">
            <h1 className="text-[20px] font-semibold">Canceled Meetings</h1>
            <p className="text-[40px] font-semibold mt-8">{totalCanceled}</p>
            <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
              This is the number of students waiting consultation
            </p>
          </div>
        </div>

        <h1 className="text-base font-medium">Today’s Meetings</h1>

        <div className="grid grid-cols-3 gap-8">
          {Interview?.map((interview, index) => (
            <ConsulationCard interview={interview} key={index} />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Consultations;
