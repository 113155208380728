import { ColumnDef, flexRender } from "@tanstack/react-table";
import { Table as ReactTableType } from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import Spinner from "@/components/Spinner";
import { useNavigate } from "react-router-dom";

interface DataTableProps<TData, TValue> {
  table: ReactTableType<TData>;
  columns: ColumnDef<TData, TValue>[];
  loading: boolean;
  title?: string;
  desc?: string;
}

export function DataTable<TData, TValue>({
  columns,
  loading,
  table,
  title,
  desc,
}: DataTableProps<Client, TValue>) {
  const navigate = useNavigate();
  // Loading or error states
  if (loading)
    return (
      <div className="h-[67vh] flex items-center justify-center">
        <Spinner />
      </div>
    );

  return (
    <div className="flex flex-col h-full ">
      <div className=" rounded-[5px] h-full border-[#ECEEF6] bg-white divide-y-2 divide-[#EEEEEE] p-6">
        <div className="pb-4">
          <h1 className="text-[20px] font-semibold  ">{title}</h1>
          <p className="text-[#8C94A3] text-sm ">{desc}</p>
        </div>
        <div className="h-full overflow-y-scroll ">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead className="w-1/5 " key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        onClick={() => navigate(`/student/${row.original.id}`)}
                        className="w-1/5 font-normal cursor-pointer"
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
