import AddButton from "@/components/AddButton";
import Container from "@/components/Container";
import Header from "@/components/Header";
import React, { useState } from "react";

import WilayaDropdown from "@/components/WilayaDropdown";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DataTablePagination } from "@/components/Pagination";
import { DataTableViewOptions } from "@/components/ColumnToggle";
import { useClients } from "@/hooks/clients/useClients";
import { columns } from "./teacherList/columns";
import { DataTable } from "./teacherList/data-table";
import { useNavigate } from "react-router-dom";
import { useTeachers } from "@/hooks/teacher/useTeacher";

type PropsTeacher = {};

const Teacher: React.FC<PropsTeacher> = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [pageIndex, setPageIndex] = useState(0); // pageIndex is 0-based
  const [pageSize, setPageSize] = useState(10);

  // Fetch clients from the API using pageIndex and pageSize
  const { teachers, refresh, meta, loading, error } = useTeachers(
    pageIndex + 1,
    pageSize,
    searchTerm
  );

  // Initialize table with pagination
  const table = useReactTable({
    data: teachers,
    columns: [
      ...columns,
      {
        id: "actions",
        cell: ({ row }) => {
          return <div className=" flex justify-end">Details</div>;
        },
      },
    ],
    pageCount: meta ? meta.lastPage : -1, // Set total page count from meta
    manualPagination: true, // Pagination is handled externally (we handle it with useClients)
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  // Handle page size change
  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize); // Update the page size state
    table.setPageSize(newPageSize); // Also update the table's page size
  };

  // Handle page index change (for next/previous page)
  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex); // Update the page index state
    table.setPageIndex(newPageIndex); // Update the table's page index
  };

  return (
    <Container>
      {/* <Header
        title="Teachers"
        placeholder="Search Teacher"
        setSearchTerm={setSearchTerm}
      /> */}
      <div className="flex flex-col  gap-4 px-4 pb-4  h-full">
        <div className="flex gap-8">
          <div className="flex justify-between items-center">
            <div className="bg-white px-8 py-4 rounded-[5px] shadow-xl shadow-gray-200/50">
              <h1 className="font-semibold text-[20px]">Number of teachers</h1>
              <p className="font-semibold text-[40px] mt-4">
                {teachers.length}
              </p>
              <p className="text-[#8C94A3] font-normal text-sm">
                This is the number of students you consulted
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="bg-white px-8 py-4 rounded-[5px] shadow-xl shadow-gray-200/50">
              <h1 className="font-semibold text-[20px]">Number of teachers</h1>
              <p className="font-semibold text-[40px] mt-4">
                {teachers.length}
              </p>
              <p className="text-[#8C94A3] font-normal text-sm">
                This is the number of students you consulted
              </p>
            </div>
          </div>
        </div>

        {/* client table  */}
        <div className=" flex-grow ">
          <DataTable
            table={table}
            columns={columns}
            loading={loading}
            error={error}
            title="Teachers"
            desc="Here you can find the teachers and their details"
          />
        </div>

        {/* pagination and column toggle */}
        <div className="mt-auto flex justify-between ">
          <div>
            <DataTableViewOptions table={table} />
          </div>
          <div className="flex justify-end w-full ">
            <DataTablePagination
              table={table}
              onPageChange={handlePageChange} // Handle page index change
              onPageSizeChange={handlePageSizeChange} // Handle page size change
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Teacher;
