import api from "./baseApi";

export const addStudent = async (student: Student) => {
  try {
    const response = await api.post("/student", student);
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const getStudents = async ({
  page = 1,
  perPage = 10,
  searchTerm,
}: {
  page?: number;
  perPage?: number;
  searchTerm?: string;
}) => {
  try {
    let query = `?page=${page}&perPage=${perPage}`;
    if (searchTerm) {
      query += `&fullname=${searchTerm}`;
    }

    const response = await api.get(`/users${query}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching students", error);
    return [];
  }
};

export const deleteStudent = async (studentId: string) => {
  try {
    const response = await api.delete(`/student/${studentId}`);
    return response;
  } catch (error) {
    console.error("Error deleting student", error);
  }
};
