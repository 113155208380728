import { useMemo } from "react";

type Interview = {
  status: string;
};

type Stats = {
  totalConsulted: number;
  totalScheduled: number;
  totalRescheduled: number;
  totalCanceled: number;
};

const useInterviewStats = (interviews: Interview[] = []): Stats => {
  return useMemo(() => {
    const stats = {
      totalConsulted: 0,
      totalScheduled: 0,
      totalRescheduled: 0,
      totalCanceled: 0,
    };

    for (const interview of interviews) {
      switch (interview.status) {
        case "consulted":
          stats.totalConsulted++;
          break;
        case "scheduled":
          stats.totalScheduled++;
          break;
        case "rescheduled":
          stats.totalRescheduled++;
          break;
        case "canceled":
          stats.totalCanceled++;
          break;
        default:
          break;
      }
    }

    return stats;
  }, [interviews]);
};

export default useInterviewStats;
