import api from "./baseApi";

export const login = async (credentials: Credentials) => {
  const response = await api.post("/auth/login", credentials);
  return response;
};
export const loginConsultant = async (credentials: Credentials) => {
  console.log("credentials", credentials);
  const response = await api.post("/auth/login/consultant", credentials);
  return response;
};
