import { Route, Routes } from "react-router-dom";
import {
  AddClient,
  BecomeATeacher,
  Clients,
  Login,
  PageNotFound,
  Teacher,
  Student,
  StudentDetail,
  TeacherDetail,
  Course,
  CourseDetail,
  Consultant,
  ConsultantDetail,
  StudentPlatform,
  StudentLogin,
  StudentSignup,
} from "./pages";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./pages/auth/ProtectedRoute";
import {
  ConsultationDetailsConsulant,
  Consultations,
  Home,
  LoginConsultant,
  Students,
} from "./pages/consultantPlatform/pages";

const App = () => {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/login" element={<Login />} />

        {/* Student platform */}
        <Route path="/student/platform" element={<StudentPlatform />} />
        <Route path="/student/platform/login" element={<StudentLogin />} />
        <Route path="/student/platform/signup" element={<StudentSignup />} />

        {/* consultant platform */}
        <Route
          path="/consultant/platform/login"
          element={<LoginConsultant />}
        />
        <Route path="/consultant/platform" element={<Home />} />
        <Route path="/consultant/platform/students" element={<Students />} />
        <Route
          path="/consultant/platform/consultations"
          element={<Consultations />}
        />
        <Route
          path="/consultant/platform/consultation-details"
          element={<ConsultationDetailsConsulant />}
        />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Teacher />
            </ProtectedRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          }
        />

        <Route
          path="/client/add"
          element={
            <ProtectedRoute>
              <AddClient />
            </ProtectedRoute>
          }
        />

        <Route path="/becomeateacher" element={<BecomeATeacher />} />

        <Route path="*" element={<PageNotFound />} />
        <Route
          path="/teachers"
          element={
            <ProtectedRoute>
              <Teacher />
            </ProtectedRoute>
          }
        />
        <Route
          path="/teacher/:id"
          element={
            <ProtectedRoute>
              <TeacherDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/students"
          element={
            <ProtectedRoute>
              <Student />
            </ProtectedRoute>
          }
        />

        <Route
          path="/student/:id"
          element={
            <ProtectedRoute>
              <StudentDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/classes"
          element={
            <ProtectedRoute>
              <Course />
            </ProtectedRoute>
          }
        />

        <Route
          path="/class/:id"
          element={
            <ProtectedRoute>
              <CourseDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/consultants"
          element={
            <ProtectedRoute>
              <Consultant />
            </ProtectedRoute>
          }
        />

        <Route
          path="/consultant/:id"
          element={
            <ProtectedRoute>
              <ConsultantDetail />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default App;
