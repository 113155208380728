import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { login } from "@/api/auth";
import { saveAuthData } from "@/utils/auth.util";

export const signInAsync = createAsyncThunk(
  "auth/login",
  async (credentials: Credentials, { rejectWithValue }) => {
    try {
      const response: any = await login(credentials);
      console.log(response);

      if (response && response.data && response.data.accessToken) {
        saveAuthData(response.data); // Save token in localStorage or cookies

        // Exclude token and get the rest of the response data
        const { accessToken, ...userWithoutToken } = response.data;

        return userWithoutToken; // Return all response data except the token
      } else {
        if (response.error) {
          return rejectWithValue(response.error);
        } else {
          return rejectWithValue("Unknown error occurred");
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  user: null,
  // const user = useSelector((state) => state.auth.user);
  role: null,
  // const role = useSelector((state) => state.auth.role);
  status: "idle",
  error: null,
};

const authSlice = createSlice({
  name: "authConsultant",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.role = null; // Reset role on logout
      localStorage.removeItem("authData");
      // Remove token from cookies
      document.cookie =
        "access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    },
    setRole: (state, action) => {
      state.role = action.payload; // Reducer to set the role
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signInAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload; // Save user info
        state.role = action.payload.role; // Save role in the state
      })
      .addCase(signInAsync.rejected, (state: any, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { logout, setRole } = authSlice.actions;
export default authSlice.reducer;
