import { Button } from "@/components/ui/button";
import Container from "@/pages/consultantPlatform/components/Container";
import Header from "@/pages/consultantPlatform/components/Header";
import { formatDateAndTime } from "@/utils/dateFormat";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const ConsultationDetailsConsulant = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const { interview } = location.state || {}; // Extract the interview object

  if (!interview) {
    return <div>No interview data provided.</div>;
  }

  return (
    <Container>
      <Header
        isSearchable
        placeholder="Consultations"
        setSearchTerm={setSearchTerm}
      />
      <div className="flex flex-col gap-8 p-12">
        {/* STUDENT INFO */}
        <div className="bg-white flex flex-col gap-6 p-8 rounded-[5px] shadow-md">
          <h1 className="text-[20px] font-semibold">Student Details</h1>
          <div className="bg-[#EEEEEE] h-px w-full" />
          <div className="flex justify-between items-center px-20">
            <div className="flex gap-8 items-center">
              <img
                className="w-[112px] h-[112px]"
                src="/assets/images/student.png"
                alt="student"
              />
              <div>
                <div className="flex flex-col gap-4">
                  <h1 className="text-2xl font-semibold">
                    {interview.Student.fullname}
                  </h1>
                  <p className="text-[#8C94A3] text-base font-normal">
                    {formatDateAndTime(interview.date).date} At{" "}
                    {formatDateAndTime(interview.date).time}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex gap-28 ">
              <div className="flex flex-col gap-2 items-center">
                <p className="font-normal text-center ">Age range</p>
                <p className="font-semibold ">7 - 10 Years</p>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="font-normal text-center ">Level</p>
                <p className="font-semibold ">A1</p>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <p className="font-normal text-center ">Status</p>
                <p className="font-semibold ">{interview.status}</p>
              </div>
            </div>
          </div>
        </div>

        {/* BUTTON INFO */}
        <div className="bg-[#EDF0FE] flex items-center gap-6 p-8 rounded-[5px] shadow-md">
          <div className="bg-[#5669C3] w-[10px] h-[10px] rounded-full animate-pulse" />
          This Meeting is due
          <span className="text-[#677EEA]">
            {formatDateAndTime(interview.date).date}
          </span>
          at {formatDateAndTime(interview.date).time}, a button will appear 30
          minutes before time, to join the call
        </div>
        <div className="bg-white flex flex-col gap-6 p-8 rounded-[5px] shadow-md">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-[20px] font-semibold">Student Details</h1>
              <p className="text-[#8C94A3] ">
                Here you can see who joined the call
              </p>
            </div>
            <Button
              onClick={() => {
                window.open(interview.url, "_blank");
              }}
            >
              Start Call
            </Button>
          </div>
          <div className="bg-[#EEEEEE] h-px w-full" />
          <div className="flex justify-between items-center px-20"></div>
        </div>
      </div>
    </Container>
  );
};

export default ConsultationDetailsConsulant;
