import { HomeIcon, MessageSquare, User2Icon } from "lucide-react";

export const TABS: Tab[] = [
  {
    title: "Home",
    route: "/consultant/platform",
    icon: HomeIcon,
    allowedUsers: ["admin", "supervisor", "consultant"],
  },
  {
    title: "Consultations",
    icon: MessageSquare,
    route: "/consultant/platform/consultations",
    allowedUsers: ["admin", "supervisor", "consultant"],
  },
  {
    title: "Students",
    icon: User2Icon,
    route: "/consultant/platform/students",
    allowedUsers: ["admin", "supervisor", "consultant"],
  },
];
