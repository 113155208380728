import React, { useState } from "react";
import { logout } from "@/redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/redux/store";
import { getUser } from "@/utils/auth.util";
import { Input } from "@/components/ui/input";

type PropsHeader = {
  setSearchTerm: (searchTerm: string) => void;
  placeholder?: string;
  isSearchable?: boolean;
};

const Header: React.FC<PropsHeader> = ({
  setSearchTerm,
  placeholder,
  isSearchable = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setSearchTerm(value); // Pass the search term to the parent component
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <header className="bg-[#F9FAFF] flex justify-between items-center px-12 py-4 ">
      <div className="mt-14   md:mt-0 pt-2 flex items-center">
        <h1 className="text-[32px] font-semibold">{placeholder}</h1>
      </div>

      <div className="-mt-10 md:mt-0 flex items-center gap-2">
        <div className="flex justify-center items-center w-[44px] h-[44px] rounded-xl shadow-md">
          <img src="/assets/icons/Bell.svg" alt="notifications" />
        </div>
        <div
          onClick={handleLogout}
          className="flex justify-center items-center w-[44px] h-[44px] rounded-xl shadow-md cursor-pointer hover:bg-destructive/20 transition duration-200"
        >
          <img src="/assets/icons/logout.svg" alt="logout" />
        </div>
      </div>
    </header>
  );
};

export default Header;
