import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import Navigation from "@/pages/consultantPlatform/components/Navigation";
import { TABS } from "@/pages/consultantPlatform/components/tabs";
import { MenuIcon } from "lucide-react";

type PropsMobileMenu = {};

const MobileMenu: React.FC<PropsMobileMenu> = () => {
  return (
    <Sheet key="left">
      <SheetTrigger className="fixed top-5 left-4 p-2  rounded-full bg-[#FBFBFB]">
        <MenuIcon />
      </SheetTrigger>
      <SheetContent side="left" className="bg-white h-[100vh]">
        <SheetHeader>
          <SheetTitle>
            <div className=" ml-3  -mt-8 h-20">
              <img src="/assets/icons/Hillal.svg" alt="logo" />
            </div>
          </SheetTitle>
          <SheetDescription className="flex flex-col h-[75vh] justify-between ">
            <Navigation tabs={TABS} />
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default MobileMenu;
