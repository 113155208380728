import React, { useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import Spinner from "@/components/Spinner";
import { useSignup } from "../../hooks/auth/useSignup";

import StepInformation from "./steps/StepInformation";
import StepContract from "./steps/StepContract";
import StepAddInformation from "./steps/StepAddInformation";
import Meeting from "./steps/Meeting";
import { useNavigate } from "react-router-dom";

interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function SignupForm({ className, ...props }: UserAuthFormProps) {
  const { signupFormValues, submitting, onSubmit } = useSignup();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => Math.max(prev - 1, 1));

  return (
    <div className=" bg-white mt-12 border border-[#C0C0C0]/[0.8] rounded-[38px]  py-12">
      <div className={cn("grid gap-6", className)} {...props}>
        <form onSubmit={signupFormValues.handleSubmit(onSubmit)}>
          {step === 1 && <StepInformation formValues={signupFormValues} />}
          {step === 2 && <StepAddInformation formValues={signupFormValues} />}
          {step === 3 && <StepContract formValues={signupFormValues} />}
          {step === 4 && <Meeting formValues={signupFormValues} />}

          <div className="flex justify-between mt-4  px-28">
            {/* {step > 1 && (
            <Button onClick={prevStep} type='button'>
              Back
            </Button>
          )} */}
            {step < 4 ? (
              <Button
                className="px-12 mt-8 w-full py-8 text-base font-medium rounded-full"
                onClick={nextStep}
                type="button"
              >
                Confirm
              </Button>
            ) : null}
          </div>
        </form>

        {step === 1 && (
          <p className="mb-8 w-full  lg:mb-0 mx-auto mt-8 flex gap-1 justify-center text-xs text-foreground">
            Already have an account?{" "}
            <div
              onClick={() => navigate("/student/platform/login")}
              className="underline text-center text-primary underline-offset-4 hover:text-primary"
            >
              Log in
            </div>{" "}
          </p>
        )}
      </div>
    </div>
  );
}
