import * as React from "react";

import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";

import { Button } from "@/components/ui/button";

import Spinner from "@/components/Spinner";
import { PasswordInput } from "@/components/ui/password-input";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useLogin } from "../../hooks/auth/useLogin";
interface UserAuthFormProps extends React.HTMLAttributes<HTMLDivElement> {}

export function LoginForm({ className, ...props }: UserAuthFormProps) {
  const { loginForm, submitting, onSubmit } = useLogin();

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <Form {...loginForm}>
        <form onSubmit={loginForm.handleSubmit(onSubmit)}>
          <div className="grid gap-2">
            <div className="grid gap-6">
              <FormField
                control={loginForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <span className="text-sm font-medium">Email</span>
                    </FormLabel>
                    <FormControl>
                      <Input placeholder="Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={loginForm.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <span className="text-sm font-medium">Password</span>
                    </FormLabel>
                    <FormControl>
                      <PasswordInput placeholder="Password" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Button
              className="px-12 mt-8 w-full py-6 text-base font-medium"
              type="submit"
              disabled={submitting}
            >
              {submitting ? <Spinner /> : "Login"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
