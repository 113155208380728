import MobileMenu from "@/components/MobileMenu";
import { TABS } from "./tabs";
import Navigation from "./Navigation";
const Sidebar = () => {
  return (
    <>
      <div className="hidden md:flex md:w-[337px] z-40    flex-col gap-4 md:fixed h-screen bg-[#5669C3] pt-8 font-normal text-[14px]">
        <div className="flex justify-center items-center">
          <img src="/assets/images/logowhite.svg" alt="logo" />
        </div>
        <Navigation tabs={TABS} />
      </div>

      <div className="md:hidden">
        <MobileMenu />
      </div>
    </>
  );
};

export default Sidebar;
