import React, { useState } from "react";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import { Label } from "@/components/ui/label";
import api from "@/api/baseApi";
import { Button } from "@/components/ui/button";
import { getUser } from "@/utils/auth.util";

const Meeting = ({ formValues }: any) => {
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [time, setTime] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false); // New state to track success
  const [errorMessage, setErrorMessage] = useState<string>("");
  const user = getUser();

  const generateRoomName = () => {
    const formattedDate = date
      ? date.toISOString().slice(0, 10).replace(/-/g, "")
      : "defaultDate";
    const formattedTime = time ? time.replace(/:/g, "") : "defaultTime";
    return `interview-${formattedDate}-${formattedTime}`;
  };

  const constructMeetingUrl = () => {
    const roomName = generateRoomName();
    return `${process.env.REACT_APP_MEET}/${roomName}`;
  };

  const meetingUrl = constructMeetingUrl();
  const handleInterviewProcess = async () => {
    if (!date || !time) {
      setErrorMessage("Please fill all the fields before proceeding.");
      return;
    }

    const subject = "Interview Invitation";
    const htmlBody = `
      <div style="font-family: Arial, sans-serif; line-height: 1.6;">
        <p>Dear Candidate,</p>
        <p>We are pleased to invite you to an interview. Below are the details:</p>
        <ul>
          <li><strong>Date:</strong> ${date.toLocaleDateString()}</li>
          <li><strong>Time:</strong> ${time}</li>
          <li><strong>Meeting Link:</strong> <a href="${meetingUrl}" target="_blank">${meetingUrl}</a></li>
        </ul>
        <p>We look forward to speaking with you.</p>
        <p>Best regards,<br>Lansly</p>
      </div>
    `;

    try {
      setLoading(true);
      setErrorMessage("");

      // Step 1: Create the student
      const studentPayload = {
        email: formValues.getValues("email"),
        fullname: formValues.getValues("fullname"),
        password: formValues.getValues("password"),
        role: "student",
        phone: formValues.getValues("phone"),
      };

      const responseStudent = await api.post("/users", studentPayload);
      console.log("responseStudent", responseStudent);

      if (
        responseStudent.status !== 201 ||
        responseStudent.data.name === "PrismaClientKnownRequestError"
      ) {
        setErrorMessage("Failed to create the student. Please try again.");
        return;
      }

      const studentId = responseStudent.data.id;

      // Step 2: Send the email
      const emailPayload = {
        email: formValues.getValues("email"),
        subject,
        text: htmlBody,
      };

      const responseMail = await api.post("/mail/confirmation", emailPayload);

      console.log("responseMail", responseMail);
      if (responseMail.status !== 201) {
        setErrorMessage("Failed to send the email. Please try again.");
        return;
      }

      // Step 3: Create the interview
      const interviewPayload = {
        url: meetingUrl,
        status: "scheduled",
        date: date.toISOString(),
        studentId, // Associate interview with the created student
        consultantId: "cm46xop0a0000kt4kf7pwkt4x", // Associate interview with the logged in consultant
      };
      console.log("interviewPayload", interviewPayload);

      const responseInterview = await api.post("/interview", interviewPayload);

      console.log("responseInterview", responseInterview);
      if (responseInterview.status === 201) {
        setSuccess(true); // Set success state to true
      } else {
        setErrorMessage("Failed to create the interview. Please try again.");
      }
    } catch (error) {
      console.error("Error in the interview process:", error);
      setErrorMessage(
        "An error occurred during the process. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    // Meeting Scheduled Screen
    return (
      <div className="flex flex-col items-center justify-center gap-8  px-8">
        <img src="/assets/images/check.svg" alt="check" />
        <h1 className="text-[40px] font-semibold mb-4 w-[40%] text-center ">
          Meeting Scheduled
        </h1>
        <p className="text-lg  text-[#5D5D5D]">
          A notification will be sent when your meeting is due
        </p>
        <p className="text-lg  text-[#5D5D5D] w-[80%] text-center">
          You cannot change the meeting time when there is one day left for it
        </p>
        <p className="text-lg  text-[#5D5D5D] w-[80%] text-center">
          Your meeting is in {date?.toLocaleDateString()} , {time}
        </p>

        <Button
          variant={"ghost"}
          onClick={() => setSuccess(false)} // Reset form to schedule another meeting
          className="text-primary"
        >
          Change meeting time
        </Button>
      </div>
    );
  }

  // Meeting Form
  return (
    <div className=" max-w-[800px] mx-auto px-8">
      <div className="flex gap-2 divide-x-2 divide-[#EEEEEE]">
        <div className="w-1/3 flex flex-col gap-8 divide-y-2 divide-[#EEEEEE] p-4">
          <p>
            We would like to schedule an interview with you! Pick a time & date!
          </p>

          <div className="space-y-4 py-4">
            <div className="flex gap-3 items-center">
              <img src="/assets/icons/Clock.svg" alt="Clock" />
              <p>30 minutes</p>
            </div>
            <div className="flex gap-3 items-start">
              <img src="/assets/icons/Info.svg" alt="Info" />
              <p>
                This is an on-site interview. Please bring your CV and any other
                documents required for the work contract.
              </p>
            </div>
          </div>
        </div>

        <div className="w-2/3 p-8 flex flex-col gap-8">
          <div className="flex gap-4 items-center">
            <Label>Date:</Label>
            <div className="w-1/2">
              <DatePicker
                date={date}
                onPickDate={(selectedDate) => setDate(selectedDate)}
              />
            </div>
          </div>

          <div className="flex gap-4 items-center">
            <Label>Time:</Label>
            <TimePicker
              value={time}
              onValueChange={(value) => setTime(value)}
            />
          </div>

          {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
        </div>
      </div>
      <div className="mx-20">
        <Button
          onClick={handleInterviewProcess}
          className="px-12 mt-8 w-full py-8 text-base font-medium rounded-full"
          disabled={loading}
          type="button"
        >
          {loading ? "Scheduling..." : "Confirm"}
        </Button>
      </div>
    </div>
  );
};

export default Meeting;
