import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { signInAsync } from "@/redux/slices/authSliceConsultant";
import { AppDispatch } from "@/redux/store";
import { useState } from "react";

export const useLogin = () => {
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const loginForm = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values: Credentials) => {
    setSubmitting(true);

    dispatch(signInAsync(values))
      .unwrap()
      .then((res) => {
        toast.success("Connexion réussie ");
        navigate("/consultant/platform");

        setSubmitting(false);
      })
      .catch((error) => {
        console.error("Failed to sign in:", error);
        setSubmitting(false);
        toast.error("Erreur de connexion, veuillez réessayer.");
      });
  };

  return { loginForm, submitting, onSubmit };
};
