export function dateFormat(timestamp: string | undefined) {
  if (!timestamp) return "";
  const date = new Date(timestamp);
  const formattedDate = `${date.getUTCDate().toString().padStart(2, "0")}/${(
    date.getUTCMonth() + 1
  )
    .toString()
    .padStart(2, "0")}.${date.getUTCFullYear()}`;
  return formattedDate;
}

export function formatDateAndTime(dateTimeString: string): {
  date: string;
  time: string;
} {
  const dateObj = new Date(dateTimeString);

  // Format date
  const optionsDate: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const formattedDate = dateObj.toLocaleDateString("fr-FR", optionsDate);

  // Format time
  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  };
  const formattedTime = dateObj.toLocaleTimeString("fr-FR", optionsTime);

  return { date: formattedDate, time: formattedTime };
}

export function formatDateStandard(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  return `${day}/${month}/${year}`;
}
