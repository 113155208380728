import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState } from "react";
import Container from "../../components/Container";
import Header from "../../components/Header";
import useGetConsultant from "../../hooks/useGetConsultant";
import { getUser } from "@/utils/auth.util";
import { DataTable } from "./studentList/data-table";
import useInterviewStats from "../../hooks/useInterviewStats";
import { columns } from "./studentList/columns";

type PropsStudent = {};

const Students: React.FC<PropsStudent> = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { id } = getUser();
  const { loading, name, students, Interview } = useGetConsultant(id);
  console.log(students);
  const { totalConsulted, totalScheduled, totalRescheduled, totalCanceled } =
    useInterviewStats(Interview);
  // Initialize table with pagination
  const table = useReactTable({
    data: students || [],
    columns: [
      ...columns,
      {
        id: "actions",
        cell: ({ row }) => {
          return <div className=" flex justify-end">Details</div>;
        },
      },
    ],
    manualPagination: true, // Pagination is handled externally (we handle it with useClients)
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Container>
      <Header
        isSearchable
        placeholder="Students"
        setSearchTerm={setSearchTerm}
      />
      <div className="flex flex-col gap-8 p-12">
        <div className="w-[calc(33%-18px)] flex flex-col bg-white p-8 rounded-[5px] shadow-md">
          <h1 className="text-[20px] font-semibold">
            Total Students Consulted
          </h1>
          <p className="text-[40px] font-semibold mt-8">{totalConsulted}</p>
          <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
            This is the number of students you consulted
          </p>
        </div>

        {/* client table  */}
        <div className=" flex-grow ">
          <DataTable
            table={table}
            columns={columns}
            loading={loading}
            title="Students"
            desc="Here you can find the students and their details"
          />
        </div>
      </div>
    </Container>
  );
};

export default Students;
