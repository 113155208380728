import { ColumnDef } from "@tanstack/react-table";

export const columns: ColumnDef<Student>[] = [
  {
    accessorKey: "fullname",
    header: "Photo",
    cell: ({ row }) => {
      return (
        <div className="">
          {" "}
          <img
            className="w-[54px] h-[54px]"
            src="/assets/images/student.png"
            alt="studen imahe"
          />
        </div>
      );
    },
  },
  {
    accessorKey: "fullname",
    header: "Name",
  },
  {
    accessorKey: "age",
    header: "Age",
  },
  {
    accessorKey: "createdAt",
    header: () => <div className="text-left">Date joined</div>,
    cell: ({ row }) => {
      const date = row.getValue("createdAt")
        ? new Date(row.getValue("createdAt"))
        : new Date();
      const formatted = new Intl.DateTimeFormat("en-US", {
        dateStyle: "medium",
      }).format(date);

      return <div className="text-left ">{formatted}</div>;
    },
  },
  {
    accessorKey: "status",
    header: "Status",
  },
  {
    accessorKey: "type",
    header: "Course Type",
  },
  {
    accessorKey: "class",
    header: "Class Type",
  },
];
