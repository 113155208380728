import { Button } from "@/components/ui/button";

import { useNavigate } from "react-router-dom";
import { LoginForm } from "./LoginForm";
import Navbar from "@/components/Navbar";

export default function LoginConsultant() {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-background ">
      <Navbar />
      <div className="max-w-screen-xl mx-auto">
        <img className="py-4" src="assets/images/logo.svg" alt="logo" />
        <div className="container bg-background  relative h-screen mt-8  flex flex-col items-center justify-start ">
          {/* login card */}
          <div className="inverted-circle bg-white  rounded-[12px] px-12">
            <div className="mx-auto flex  flex-col justify-center items-center  py-12  sm:w-[350px] ">
              <h1 className="font-semibold text-[48px] ">Log in</h1>
              <p>Log in to your account Consultant</p>

              <div className="w-[100%] mt-12">
                <LoginForm />
              </div>

              <p className="mb-8 lg:mb-0 mx-auto mt-8 flex gap-1 text-center text-xs text-foreground">
                Dont have an account?{" "}
                <div
                  onClick={() => navigate("/signup")}
                  className="underline text-primary underline-offset-4 hover:text-primary"
                >
                  Sign up Now
                </div>{" "}
              </p>
              <p className="px-8 mx-auto mt-auto flex gap-1 text-center text-xs text-textDark">
                Terms and conditions | Privacy policy
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
