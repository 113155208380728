import { getConsultant } from "@/api/consultant";
import { useState, useEffect } from "react";

const useGetConsultant = (id: string) => {
  const [consultant, setConsultant] = useState<ConsultantPlatform | null>(null);
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchConsultant = async () => {
      try {
        // Make an API call to fetch Clients
        const response = await getConsultant(id);

        // EACH INTERVIEW HAS A STUDENT, ORGINZE THEM INTO AN ARRAY
        const students = response.Interview.map(
          (interview: Interview) => interview.Student
        );
        setStudents(students);
        setConsultant(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching consultant:", error);
        setLoading(false);
      }
    };

    fetchConsultant();
  }, [id]);

  return { ...consultant, students, loading };
};

export default useGetConsultant;
