import { formatDateAndTime } from "@/utils/dateFormat";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  interview: Interview;
};

const ConsulationCard: React.FC<Props> = ({ interview }) => {
  const navigate = useNavigate();
  const { url, status, date, Student } = interview;

  const handleCardClick = () => {
    navigate(`/consultant/platform/consultation-details`, {
      state: { interview },
    });
  };

  return (
    <div
      className="bg-[#E9ECFB] p-8 flex flex-col gap-8 cursor-pointer"
      onClick={handleCardClick}
    >
      <div className="flex justify-around items-center">
        <img
          className="w-[112px] h-[112px]"
          src="/assets/images/student.png"
          alt="student"
        />
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl font-semibold">{Student.fullname}</h1>
          <p className="text-[#8C94A3] text-base font-normal">
            {formatDateAndTime(date).date} At {formatDateAndTime(date).time}
          </p>
        </div>
      </div>
      <div className="flex gap-12 mx-auto">
        <div className="flex flex-col gap-2 items-center">
          <p className="font-normal text-center ">Age range</p>
          <p className="font-semibold ">7 - 10 Years</p>
        </div>
        <div className="flex flex-col gap-2 items-center">
          <p className="font-normal text-center ">Level</p>
          <p className="font-semibold ">A1</p>
        </div>
        <div className="flex flex-col gap-2 items-center">
          <p className="font-normal text-center ">Status</p>
          <p className="font-semibold ">{status}</p>
        </div>
      </div>
    </div>
  );
};

export default ConsulationCard;
