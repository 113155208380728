import { useState } from "react";
import Container from "../../components/Container";
import { getUser } from "@/utils/auth.util";
import Header from "../../components/Header";
import useGetConsultant from "../../hooks/useGetConsultant";
import useInterviewStats from "../../hooks/useInterviewStats";

const Home = () => {
  const { fullname } = getUser();
  const [searchTerm, setSearchTerm] = useState("");
  const { id } = getUser();
  const { loading, name, Interview } = useGetConsultant(id);

  const { totalConsulted, totalScheduled, totalRescheduled, totalCanceled } =
    useInterviewStats(Interview);

  return (
    <Container>
      <Header
        isSearchable
        placeholder="Consultations"
        setSearchTerm={setSearchTerm}
      />
      <div className="flex flex-col gap-8 p-12">
        <div className="flex justify-between h-[190px] items-center bg-[#5669C3] px-12 pb-4 rounded-[5px] shadow-md">
          <div className="text-white space-y-4">
            <h1 className="text-[40px] font-semibold">Welcome Back, {name}</h1>
            <p className="text-[18px] font-normal">
              You have {Interview?.length} new student added to your waitlist
            </p>
          </div>
          <div className=" h-[209px] mb-12">
            <img src="/assets/images/welcome.svg" alt="welcome" />
          </div>
        </div>

        <div className="w-[calc(33%-18px)] flex flex-col bg-white p-8 rounded-[5px] shadow-md">
          <h1 className="text-[20px] font-semibold">
            Total Students Consulted
          </h1>
          <p className="text-[40px] font-semibold mt-8">{totalConsulted}</p>
          <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
            This is the number of students you consulted
          </p>
        </div>

        <div className="flex gap-8 w-full">
          <div className="w-1/3 flex flex-col bg-white p-8 rounded-[5px] shadow-md">
            <h1 className="text-[20px] font-semibold">Scheduled Meetings</h1>
            <p className="text-[40px] font-semibold mt-8">{totalScheduled}</p>
            <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
              This is the number of students you consulted
            </p>
          </div>
          <div className="w-1/3 flex flex-col bg-white p-8 rounded-[5px] shadow-md">
            <h1 className="text-[20px] font-semibold">Rescheduled Meetings</h1>
            <p className="text-[40px] font-semibold mt-8">{totalRescheduled}</p>
            <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
              This is the number of students waiting consultation
            </p>
          </div>
          <div className="w-1/3 flex flex-col bg-white p-8 rounded-[5px] shadow-md">
            <h1 className="text-[20px] font-semibold">Canceled Meetings</h1>
            <p className="text-[40px] font-semibold mt-8">{totalCanceled}</p>
            <p className="text-[14px] font-normal text-[#8C94A3] mt-4">
              This is the number of students waiting consultation
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Home;
